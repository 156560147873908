


























































































import {Component, Prop, Vue } from 'vue-property-decorator';
import { toReadableDateFormat, wagesPer } from '@/utilities/filters';
import { SalaryTable } from '@/interfaces/salaryTable';
import { yesNo } from '@/utilities/filters';

@Component({
  filters: { toReadableDateFormat, wagesPer, yesNo },
  components: {},
})
export default class SalaryTableDetail extends Vue {
  @Prop({ required: true }) private salaryTable!: SalaryTable;
  @Prop({ default: false }) private showInlenersSalaryTableFields!: boolean;
  @Prop({ default: false }) private hourlyWages!: boolean;
  private aardTypesMap = {
    AD: 'Administratief',
    TE: 'Technisch',
    ME: 'Medisch',
    IN: 'Industrieel',
  };
  private advLabelMap = {
    percentage: 'Vast percentage',
    hours_per_week: 'ADV uren per week',
    hours_per_year: 'ADV uren per jaar',
    days_per_year: 'ADV dagen per jaar',
  };
  private getPeriodicals(ageTable: { [payscale: number]: { [periodical: number]: number }}): number[] {
    let periodicals: number[] = [];
    Object.values(ageTable).forEach((payscale) => {
      if (Object.keys(payscale).length > periodicals.length) {
        periodicals = Object.keys(payscale).map((periodical) => parseInt(periodical, 10));
      }
    });
    return periodicals;
  }

  private getCellWage(
    ageTable: { [payscale: string]: { [periodical: number]: number } },
    scaleKey: string,
    periodical: number,
    wagesDividedBy: number,
    ) {
    if (!ageTable[scaleKey][periodical]) {
      return '';
    }
    const monthWage = ageTable[scaleKey][periodical].toString().replace(/\.0+$/, '');
    if (!this.hourlyWages) {
      return monthWage;
    }
    // Round cents up.
    const hourlyWage = Math.ceil((parseFloat(monthWage) / wagesDividedBy) * 100) / 100;
    return hourlyWage.toFixed(2);
  }

  private sortPayscales(payscales: string[]) {
    if (payscales.every((value) => value.match(/^\d+$/))) {
      return [...payscales].sort((a, b) => parseInt(a, 10) > parseInt(b, 10) ? 1 : -1);
    }
    return [...payscales].sort();
  }
}
